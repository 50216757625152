// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Configuração do Firebase
const firebaseConfig = {
  apiKey: "AIzaSyC-Kz5jWx1vJQlKpdmosCud1zCPLQsBcOM",
  authDomain: "abraaoan-6ba46.firebaseapp.com",
  projectId: "abraaoan-6ba46",
  storageBucket: "abraaoan-6ba46.appspot.com",
  messagingSenderId: "952123126192",
  appId: "1:952123126192:web:f59366057bacfa3efda675",
  measurementId: "G-P3QKXDZET4"
};

// Inicializando o Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app, analytics };