import React from 'react';
import "../styles.scss";
import aboutIcon from '../assets/about.png';
import githubIcon from '../assets/github.png';
import stackoverflowIcon from '../assets/stackoverflow.png';
import linkedInIcon from '../assets/linkedin.png';
import mediumIcon from '../assets/medium.png';

const links = [
    {
        icon: githubIcon,
        title: "GitHub",
        link: "https://github.com/abraaoan"
    },
    {
        icon: stackoverflowIcon,
        title: "StackOverflow",
        link: "https://stackoverflow.com/users/1826898/abraaoan"
    },
    {
        icon: linkedInIcon,
        title: "LinkedIn",
        link: "https://www.linkedin.com/in/abraaoan/"
    },
    {
        icon: mediumIcon,
        title: "Medium",
        link: "https://medium.com/@abraaoan"
    }
];

const About = () => {
    return (
        <div>
            <h1 className="text-wrap">About</h1>
            <br />
            <div className='row d-flex align-items-center'>
                <img className='col-4 col-md-4 h-25' src={aboutIcon} style={{ width: '240px', height: '240px' }} />
                <p className="col-md-7 text-white">I'm a mobile software developer with a passion for creating seamless, user-centered experiences on iOS. Over the years, I've had the opportunity to work on a diverse range of projects—from e-commerce to banking applications—each helping me hone my skills and deepen my understanding of what makes a great app. I’m committed to writing clean, efficient code and thrive in collaborative, fast-paced environments where I can contribute to innovative solutions. When I’m not coding, you might find me exploring new tech trends or finding inspiration in other design disciplines. I’m always excited to take on new challenges that push the boundaries of mobile development.</p>
            </div>
            <div className='row text-white justify-content-start' style={{ marginTop: 80 }}>
                <h4 style={{marginBottom: 30}}>Follow me</h4>
                { links.map((page, index) => (
                    <div className='col-md-auto col-sm-6'>
                    <button type='button' className='followButton' onClick={() => {
                        window.open(page.link, "_blank");
                    }}>
                        <div className='followIconCol'><img src={page.icon} className='followIcon' /></div>
                        <div className='followTitle'>{page.title}</div>
                    </button>
                </div>
                ))}
            </div>
        </div >
    );
};

export default About;
