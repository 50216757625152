import React from 'react';
import "../styles.scss";
import icon from '../assets/swiftIcon.png';

const Navbar = () => {
    return (
        <div className='navbar-position'>
            <div className='container'>
                <nav className="navbar navbar-expand-lg navbar-dark navbar-style">
                    <a className="navbar-brand" href="#">
                        <img src={icon} width="30" height="30" alt="" />
                    </a>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <a className="nav-link" href="#">Home</a>
                            <a className="nav-link" href="#experience">Experience</a>
                            <a className="nav-link" href="#skills">Skills</a>
                            <a className="nav-link" href="#about">About</a>
                        </div>
                    </div>
                </nav>
            </div>
        </div >
    );
};

export default Navbar;
