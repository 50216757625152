import React from 'react';
import "../styles.scss";
import ExperienceCard from './ExperienceCard';
import ceiiaIcon from '../assets/companies/ceiia.png';
import blipIcon from '../assets/companies/blip.png';
import communyIcon from '../assets/companies/communy.png';
import eldoradoIcon from '../assets/companies/eldorado.png';
import itsectorIcon from '../assets/companies/itSector.png';
import neemuIcon from '../assets/companies/neemu.png';
import redeIcon from '../assets/companies/redeAmazonica.png';
import sidiaIcon from '../assets/companies/sidia.png';


const experiences = [
    {
        imgSrc: ceiiaIcon,
        title: "CEiiA, Porto - Engenheiro | Mobile Software Developer",
        date: "Dec 2023 - Present",
        description: "Currently working on a mobility project, building an interface in Swift for integrating multiple services. My role includes developing and optimizing user interactions to enhance service accessibility."
    },
    {
        imgSrc: blipIcon,
        title: "Blip, Porto — iOS Engineer",
        date: "Feb 2022 - Jun 2023",
        description: "Contributed to the development of the PaddyPower native app, focusing on clean code practices, Unit Testing, and UI Testing. My responsibilities included implementing robust testing strategies and applying design patterns, which allowed me to deepen my understanding of testing and software quality."
    },
    {
        imgSrc: itsectorIcon,
        title: "ITSector, Porto — iOS Engineer",
        date: "Mar 2021 - Sep 2021",
        description: "Led the iOS development for the Montepio Bank MBWay Project’s second phase. My tasks included completing service integrations and finalizing specific features. I primarily used Objective-C with Storyboard, REST APIs, Core Data, and GIT in a legacy codebase, which presented unique challenges. One of the main hurdles was coordinating across teams, as interdepartmental communication was essential to meet delivery goals."
    },
    {
        imgSrc: eldoradoIcon,
        title: "Eldorado, Manaus — iOS Tech Leader",
        date: "Nov 2020 - Mar 2021",
        description: "Served as tech lead on the MotoTalk (Motorola) project, guiding the dev team and updating legacy Objective-C code. Eldorado is recognized for its extensive portfolio of national and international clients, providing valuable experience in leadership and legacy code management."
    },
    {
        imgSrc: sidiaIcon,
        title: "SIDIA, Manaus — Senior iOS Developer",
        date: "Jan 2019 - Nov 2020",
        description: "Worked on a Samsung project developing iOS interfaces for various service integrations. SIDIA, an R&D institute, focuses on creating innovative solutions for local and global markets, offering a dynamic environment for applying advanced development practices."
    },
    {
        imgSrc: neemuIcon,
        title: "Neemu (Linx), Manaus — Senior iOS Developer",
        date: "Sep 2012 - Jan 2019",
        description: "Responsible for enhancing mobile experiences for e-commerce clients, notably maintaining and developing the Mobly Store app for three years. I also worked on solutions for Sephora. Key technologies included Objective-C, Swift, C++, JS, PHP, and Node, with a focus on MVP architecture and object-oriented programming."
    },
    {
        imgSrc: communyIcon,
        title: "Communy, Manaus — Partner and iOS Developer",
        date: "Jan 2016 - Jan 2019",
        description: "Co-founded and developed Communy, an app aimed at facilitating communication among residents, trustees, and administrators. A significant challenge was adapting the Android standard layout for iOS to ensure consistency and usability."
    },
    {
        imgSrc: redeIcon,
        title: "Rede Amazônica, Manaus — Developer",
        date: "Jan 2009 - Sep 2012",
        description: "Started as an intern with Portal Amazônia, where I first worked with PHP, MYSQL, CSS, and HTML. Then I joined the development team as a junior programmer, eventually moving into mobile development after studying Objective-C in 2011, which sparked my interest in iOS development."
    }
];


const Projects = () => {
    return (
        <div>
            <h1 className="text-wrap">Experience</h1>
            <p className="text-white">Here are some of my experience over the years</p>
            <div className="project-descrition">
                {experiences.map((exp, index) => (
                    <ExperienceCard
                        key={index}
                        imgSrc={exp.imgSrc}
                        title={exp.title}
                        date={exp.date}
                        description={exp.description}
                    />
                ))}
            </div>
        </div >
    );
};

export default Projects;
