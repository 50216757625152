import React from 'react';
import "../styles.scss";

const ExperienceCard = ({ imgSrc, title, date, description }) => {
    return (
        <div className="row project-card">
            <div className="col-auto">
                <img src={imgSrc || ""}  width="60" height="60"/>
            </div>
            <div className="col">
            <h2 className='m-1'>{title}</h2>
            <p className="text-white-50 m-1">{date}</p>
            <p className="text-white m-1">{description}</p>
            </div>
        </div>
    );
};

export default ExperienceCard;
