import React from 'react';
import { app } from "./firebase";
import { getAnalytics, logEvent } from "firebase/analytics";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "./styles.scss";
import profileImage from './assets/home_icon.png';
import Navbar from './components/Navbar';
import Projects from './components/Projects';
import Skill from './components/Skill';
import About from './components/About';

const firebaseConfig = {
    apiKey: "AIzaSyC-Kz5jWx1vJQlKpdmosCud1zCPLQsBcOM",
    authDomain: "abraaoan-6ba46.firebaseapp.com",
    projectId: "abraaoan-6ba46",
    storageBucket: "abraaoan-6ba46.firebasestorage.app",
    messagingSenderId: "952123126192",
    appId: "1:952123126192:web:f59366057bacfa3efda675",
    measurementId: "G-P3QKXDZET4"
}

const analytics = getAnalytics(app);

function App() {
    return (
        <div>
            <Navbar />
            <div className="container">
                <div className="vh-100">
                    <div className="row align-items-center d-flex justify-content-between h-100">
                        <div className="col-md-6">
                            <div className="text-left">
                                <h1 className="text-wrap">Hi there!<br /> I'm Abraao, iOS Developer</h1>
                                <p className="w-100 text-white text-break">In love with technology and mobile world. Senior iOS Developer, Web Dev and more...</p>
                                <button type="button" className="btn btn-light" onClick={() => {
                                    logEvent(analytics, "lets_talk_click", { platform: "LinkedIn" });
                                    window.open("https://www.linkedin.com/in/abraaoan/", "_blank");
                                }}>Let's talk</button>
                            </div>
                        </div>
                        <div className="col-md-6 text-center">
                            <img className="img-fluid" style={{ width: '80%' }} src={profileImage} alt="" />
                        </div>

                    </div>
                </div>

                <div id="experience" style={{ paddingTop: 60 }}>
                    <Projects />
                </div>

                <div id="skills" style={{ paddingTop: 60, marginTop: 100 }}>
                    <Skill />
                </div>

                <div id="about" className="vh-100" style={{ paddingTop: 60, marginTop: 100 }}>
                    <About />
                </div>

                <div className="vh-100">
                    <div className="row w-100 h-100 justify-content-md-center">
                        <div className="w-35 text-center align-self-center">
                            <h2>Thank you <span role="img" aria-label="smiling">😊</span></h2>
                            {console.log("Firebase inicializado:", app)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
