import React from 'react';
import "../styles.scss";
import skillIcon from '../assets/skill.png';
import scrumIcon from '../assets/scrum.png'

const Skill = () => {
    return (
        <div>
            <h1 className="text-wrap">Skills</h1>
            <div className='row d-flex justify-content-between'>
                <p className="col-md-7 text-white">Below is a comprehensive overview of my skill set, developed through years of experience in mobile development. My expertise covers a range of areas, from technical proficiency in programming languages and frameworks to advanced testing, deployment, and project management practices. These skills enable me to create high-quality, user-centric applications while effectively collaborating with teams and driving continuous improvement in each project.</p>
                <div className='col-6 col-md-4'>
                <img src={skillIcon} style={{width: "387px", height: "258px"}} />
                </div>
            </div>
            <div className="text-white" style={{paddingTop: 25}}>
                <h2>Technical Skills</h2>
                <div className="row">
                    <div className="col">
                        <p className="m-0">Programming Languages</p>
                        <ul className="text-white-50">
                            <li>Swift</li>
                            <li>Objective-C</li>
                            <li>Javascript</li>
                            <li>C++</li>
                            <li>PHP</li>
                            <li>SQL</li>
                            <li>MongoDB</li>
                        </ul>
                    </div>
                    <div className="col">
                        <p className="m-0">Mobile Development</p>
                        <ul className="text-white-50">
                            <li>iOS SDK</li>
                            <li>SwiftUI</li>
                            <li>UIkit</li>
                            <li>Core Data</li>
                            <li>Core Animation</li>
                            <li>React Native</li>
                            <li>Flutter</li>
                            <li>RESTful APIs</li>
                            <li>JSON/XML parsing</li>
                        </ul>
                    </div>
                    <div className="col">
                        <p className="m-0">Frameworks & Libraries</p>
                        <ul className="text-white-50">
                            <li>SPM</li>
                            <li>CocoaPods</li>
                            <li>RxSwift</li>
                            <li>Alamofire</li>
                            <li>Firebase</li>
                            <li>Core Location</li>
                            <li>AVFoundation</li>
                            <li>Combine</li>
                            <li>MVVM, MVP, MVC architecture</li>
                        </ul>
                    </div>
                    <div className="col">
                        <p className="m-0">Testing & Quality Assurance</p>
                        <ul className="text-white-50">
                            <li>Unit Testing</li>
                            <li>UI Testing(UITest)</li>
                            <li>XCTest</li>
                            <li>Test-Driven Development(TDD)</li>
                            <li>Code Review Best Practices</li>
                            <li>Continuous Integration(CI) with Jenking or Github Actions</li>
                        </ul>
                    </div>
                </div>
                <div className="row d-flex justify-content-start" style={{ marginTop: 45 }}>
                    <div className="col">
                        <p className="m-0">Version Control & Collaboration</p>
                        <ul className="text-white-50">
                            <li>Git (GitHub, GitLab, Bitubucket)</li>
                            <li>Git Flow</li>
                            <li>Pull Request Workflow</li>
                            <li>Agile/Scrum Methodologies</li>
                            <li>JIRA, Trello</li>
                        </ul>
                    </div>
                    <div className="col">
                        <p className="m-0">App Deployment & Maintenance</p>
                        <ul className="text-white-50">
                            <li>App Store Connect</li>
                            <li>TestFlight</li>
                            <li>App Store submission & optimization</li>
                            <li>Crashlytics for bug tracking</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: 80 }}>
                <h2 className="text-wrap">Soft Skills</h2>
                <ul className="text-white">
                    <li>Problem Solving – Skilled at troubleshooting and finding efficient solutions in complex codebases.</li>
                    <li>Team Leadership – Experience leading mobile development teams and mentoring junior developers.</li>
                    <li>Communication – Excellent communicator with experience in cross-functional team collaboration.</li>
                    <li>Adaptability – Quickly adapts to new tools, languages, and project requirements.</li>
                    <li>Attention to Detail – Focused on code quality, user experience, and precise functionality.</li>
                    <li>Continuous Learning – Passionate about keeping up-to-date with the latest technologies and best practices in mobile development.</li>
                </ul>
            </div>
            <div className="row" style={{ marginTop: 80 }}>
                <div className="col">
                    <h2 className="text-wrap">Project Management & Methodologies</h2>
                    <ul className="text-white">
                        <li>Agile (Scrum, Kanban)</li>
                        <li>Waterfall</li>
                        <li>Sprint Planning & Retrospectives</li>
                        <li>Feature Prioritization</li>
                        <li>Documentation & Technical Writing</li>
                    </ul>
                </div>
                <div className="col">
                    <img src={scrumIcon} style={{ width: "258px", height: "258px" }} />
                </div>
            </div>
            <div style={{ marginTop: 80 }}>
                <h2 className="text-wrap">UI/UX Design Skills</h2>
                <ul className="text-white">
                    <li>Prototyping with Figma or Sketch</li>
                    <li>User Interface Design principles</li>
                    <li>Accessibility best practices</li>
                    <li>User-centered Design approach</li>
                </ul>
            </div>
        </div >
    );
};

export default Skill;
